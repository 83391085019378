// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/constants/routes.constent.ts"
);
import.meta.hot.lastModified = "1726716723006.0098";
}
// REMIX HMR END

export const Routes = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password',
  RESEND_ACTIVATION_LINK: '/resend-activation-link',
  CATEGORIES: '/categories',
  TEAM: '/team',
  TEAM_ADD: '/team/add',
  SUPPORT: '/support',
  SUPPORT_ALLOW_IMPERSONATE: '/support/allow-impersonate',
  SUPPORT_CONTACT_US: '/support/contact-us',
  SUPPORT_TICKETS: '/support/tickets',
  SUPPORT_TICKETS_CREATE: '/support/create-ticket',
  PLACE_AN_ORDER: '/place-an-order',
  COMPANY_INFORMATION: '/company-info',
  COMPANY_PROFILE: '/company-profile',
  SHIPPING_ADDRESS: '/shipping-address',
  ORDERS: '/order',
  INVOICES: '/invoices',
  STATEMENTS: '/statements',
  PROMOTIONS: '/promotions/available-promotion',
  MY_PROMOTIONS: '/promotions/my-promotion',
  CERTIFICATE_GENERATION: '/certificate-generation',
  WISHLIST: '/wishlist',
  PRODCUT_COMPARISON: '/product-comparison',
  PRODUCT: '/product',
  SETTINGS: '/settings',
  CART_LIST: '/cart-list',
  ORDER_SUCCESSFUL: '/order-successful',
  FILTER_METALS: '/filter-metals',
  SAFETY: '/safety',
  ORDER_DETAILS: '/order-details',
  PENDING_ORDER: '/pending-order',
  SINGLE_PENDING_ITEM: '/single-pending-item',
  PROFILE: '/profile',
  LOGOUT_SUBSCRIBE: '/logout/subscribe',
  // PERMISSIONS_SUBSCRIBE: '/permissions/subscribe',
  EVENTS_SUBSCRIBE: '/events/subscribe',
  NOTIFICATIONS_NEW: '/notification/new',
  NOTIFICATIONS_PREVIOUS: '/notification/previous',
  // NOTIFICATIONS_SUBSCRIBE: '/notifications/subscribe',
  ROLE_SUBSCRIBE: '/role/subscribe',
  DISTRIBUTOR_INFO: '/distributor-information',
  DISTRIBUTOR_PROFILE: '/distributor-profile',
  TRACK_AN_ORDER: '/trackAnOrder',
  ORDER_ERROR: '/order-error',
};
